<template>
  <form @submit.prevent="onSubmit(true)">
    <div class="md-layout mb-10">
      <div class="md-layout-item">
        <h2 class="text-uppercase text-2xl">Create new question <small class="opacity-50">{{ type }}</small></h2>
      </div>
      <div class="md-layout-item flex justify-end">
        <md-button @click="$router.go(-1)" class="rounded">
          <md-icon>list</md-icon>  Back Question List
        </md-button>
      </div>
    </div>
    <div class="md-layout">
      <!-- 01 ) full width row -->
      <div class="md-layout-item md-layout md-gutter md-size-100 mb-6">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <SelectComponent
            :placeholder="false"
            label="Choose Question Type"
            return-type="object"
            @getObject="onChangeQuestionComponent"
            class="w-64"
            :items="questionTypes"
            :value="selectQuestionType"
          />
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100 mt-3 py-2 flex justify-end">
          <md-button v-if="getSelectedQuestionType.is_sample_answer === 1 || this.is_sample_answer === 1" :disabled="this.question_index != null && this.question_index != 0 ? false : true" @click="onChooseSamleAnswer(false)" class="ml-0 rounded p-3 bg-victoria text-white">
            <md-tooltip v-if="this.question_index == null" id="set_explation_tooltip" md-direction="top">If you want to Choose Sample Answer, you have to add question index</md-tooltip>
               Choose Sample Answer
            </md-button>
          <md-button @click="onShowStudyGuide(false)" class="rounded p-3 bg-green text-white">
            Set Study Guide
          </md-button>
        </div>
      </div>
      <!-- 02 ) full width row -->
      <div class="md-layout-item md-layout md-gutter md-size-100">
    
      </div>
      <div class="md-layout-item md-layout md-gutter md-size-100 mt-4" style="align-items: baseline;">
        <div class="md-layout-item">
          <InputFieldComponent
            label="Question Index"
            @input="indexInput"
            v-model.trim="$v.form.index.$model"
            :message="!$v.form.index.required && $v.form.index.$dirty ? 'Index Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-layout" style="align-items: baseline;">
          <div class="md-layout-item flex">
            <SelectComponent
              :items="source"
              placeholder="Select Source"
              label="Source"
              class="basis"
              v-model.trim="$v.form.source.$model"
              :message="!$v.form.source.required && $v.form.source.$dirty ? 'Field is required' : null"
            />
          </div>
          <div class="md-layout-item flex">
            <SelectComponent
              :items="frequency"
              placeholder="Select Frequency"
              label="FREQUENCY"
              class="basis"
              v-model.trim="form.frequency"
            />
          </div>
          <div  class="md-layout-item block justify-center  flex-col ">
            <md-button
              :disabled="this.question_index != null && this.question_index != 0 ? false : true"
              class="rounded bg-victoria text-white text-capitalize md-setbuttom"
              @click="showSetExplanation(false)"
            >
            <md-tooltip v-if="this.question_index == null" id="set_explation_tooltip" md-direction="top">If you want to set explanation, you have to add question index</md-tooltip>
              Set Explanation
            </md-button>
          </div>
        </div>
      </div>
      <components 
        ref="child" 
        v-model="form.title" 
        :is="getSelectedQuestionType.component"
      ></components>

      <!-- 07 ) full width row -->
      <div class="md-layout-item md-layout md-gutter md-size-100 mt-6">
        <div class="md-layout-item flex justify-end">
          <md-checkbox v-model="createAnother" class="md-secondary">Create another</md-checkbox>
          <Button
            @click="onSubmit(false)"
            :loading="draftLoading"
            class="bg-alto rounded"
            label="Save as dreft"
            type="submit"
            >
          </Button>
          <Button
            :loading="loading"
            class="bg-victoria rounded text-white mr-0"
            label="Publish"
            type="submit"
            >
          </Button>
        </div>
      </div>
    </div>
    <Dialog class="md-dailog__teacher" id="dailog_teacher">
       <components  :is="dialogComponent" :types="type" :question_id="question_id" :content="content" @close="showSecondDialog = false"></components>
    </Dialog>
  </form>
</template>

<script>
import {
  Button,
  Dialog,
  TextEditor,
  SelectComponent,
  InputFieldComponent,
  RadioButtonComponent,
} from "@/components";
import questionComponents from "@/components/molecule/question/items";
import ChooseSampleAnswer from "@/components/molecule/question/ChooseSampleAnswer";
import SetExplanation from "@/components/molecule/question/SetExplanation";
import StudyGuide from "@/components/molecule/question/StudyGuide";
import VueTimepicker from 'vue2-timepicker'
import cms from "@/data/cms"
import { mapMutations, mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { secondToTime, timeToSecond } from '@/utils';

export default {
  name: "TheQuestionCreateView",
  components: {
    Button,
    Dialog,
    TextEditor,
    SelectComponent,
    SetExplanation,
    VueTimepicker,
    StudyGuide,
    RadioButtonComponent,
    ChooseSampleAnswer,
    InputFieldComponent,
    ...questionComponents
  },
  data() {
    return {
      path:'pte-question',
      content: {},
      loading: false,
      draftLoading: false,
      preTime: '00:00',
      answerTime: '',
      prompt: '',
      createAnother: false,
      setExplanation: false,
      dialogComponent: 'ChooseSampleAnswer',
      showSecondDialog: false,
      source: cms.source,
      frequency: cms.frequency,
      is_sample_answer: false,
      form: {
        question_type_id: '',
        index: "",
        prompt_type: "DEFAULT",
        prompt: "this is question",
        preparation_time_type: "DEFAULT",
        preparation_time: 0,
        answer_time_type: "DEFAULT",
        answer_time: 0,
        source: "",
        frequency: "",
        active: true,
        title: null,
        type: "QUESTION",
        question_options: [],
        is_requested: false
      },
      question_index: null,
      question_id: null,
      selectQuestionType: ''
    };
  },
  computed: {
    ...mapGetters({
      questionTypes: "question/getQuestionTypes",
      getSelectedQuestionType: "question/getSelectedQuestionType"
    }),
    type() {
        return this.$route.query.type
    },
    questionId() {
        return this.$route.params && this.$route.params.id
    },
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
      setSelectedQuestionType: "question/setSelectedQuestionType",
      setStudyGuideShow: "studyGuide/setStudyGuideShow",
      setExplanationShow: "explanation/setExplanationShow",
    }),
    ...mapActions({
      submitQuestion: 'question/actSubmitQuestion',
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actQuestionTypeDetails: "question/actQuestionTypeDetails",
      actListStudyGuide: "studyGuide/actListStudyGuide",
      actListSampleAnswer: "sampleAnswer/actListSampleAnswer",
      actUpdateQuestion: "question/actUpdateQuestion",
      actQuestionDetails: 'question/actQuestionDetails',
    }),
    indexInput(val){
      this.question_index = val
    },
    resetValidatioin() {
      //clear validation message
      setTimeout(() => {
        this.$v.$reset();
        this.$refs.child.$v.$reset();
      }, 10);
    },
    onScrollTop() {
      setTimeout(() => {
        var element = document.querySelector("#scrollTop");
        if(element !== null){
          element.scrollIntoView({ behavior: 'smooth', block: 'end'})
        }
      },10);
    },
    clearData() {
      this.form= {
        question_type_id: '',
        index: "",
        prompt_type: "DEFAULT",
        prompt: "this is question",
        preparation_time_type: "DEFAULT",
        preparation_time: 0,
        answer_time_type: "DEFAULT",
        answer_time: 0,
        source: "",
        frequency: "",
        active: true,
        title: null,
        type: "QUESTION",
        question_options: [],
        is_requested: false
      };
      this.$refs.child.clearData();
      this.onScrollTop();
      
      this.resetValidatioin();
    },
    onSubmit(active) {
      this.onScrollTop();
      this.form.preparation_time = timeToSecond(this.preTime);
      this.form.answer_time = timeToSecond(this.answerTime);

      this.$v.$touch();
      this.$refs.child.$v.$touch();

      if(this.$v.$invalid || this.$refs.child.$v.$invalid) {
        return;
      }

      this.form.question_type_id = this.getSelectedQuestionType.id;
      this.form.active = active;

      if(typeof this.form.title != 'string') {
        this.form.title = '';
      }

      this.form = {...this.form, ...this.$refs.child.form}
      this[(active == false) ? 'draftLoading' : 'loading'] = true;
      
      if(this.questionId){
          this.actUpdateQuestion({
            questionId: this.questionId,
            payload: this.form
          })
          .then(message => {
            this.setShowSnackbar(message);
            this.$router.go(-1);
          }).catch(error => {
            this.setShowSnackbar(error.response.data.message);
          });
      } else {
          this.submitQuestion(this.form)
            .then(response => {
              this.setShowSnackbar(response.message);
              if(!this.createAnother) {
                this.$router.go(-1)
              } else {
                this.clearData();
              }
          }).catch(error => {
            this.setShowSnackbar(error.response.data.message);
          }).finally(() => this[(active == false) ? 'draftLoading' : 'loading'] = false);
      }
    },
    onChangeQuestionComponent(item) {
      this.setSelectedQuestionType(item);
      this.actQuestionTypeDetails(item.id)
        .then( item => {
          this.assignUtilsForQuestion(item);
          this.is_sample_answer = item.is_sample_answer
        });
    },
    onChooseSamleAnswer(active) {
      setTimeout(() => {
         document.querySelector(".md-dailog__writing").style.display = "none"
         if(document.querySelector("#dailog_vocabulary")){
            document.querySelector("#dailog_vocabulary").style.display = "none"
         }
      },50)
      this.content = this.getSelectedQuestionType;
      // question create and draped 
      this.form.question_type_id = this.getSelectedQuestionType.id;
      this.form.active = active;

      this.submitOnlyTitle(active)

      this.dialogComponent = 'ChooseSampleAnswer';
      this.dialog(true)
    },
    showSetExplanation(active) {
      this.content = this.getSelectedQuestionType;
      // question create and draped 
      this.form.question_type_id = this.getSelectedQuestionType.id;
      this.form.active = active;

      this.submitOnlyTitle(active)

       setTimeout(() =>{
         document.querySelector(".md-dailog__writing").style.display = "none"
          if(document.querySelector("#dailog_vocabulary")){
            document.querySelector("#dailog_vocabulary").style.display = "none"
          }
       },50)
      this.dialogComponent = 'SetExplanation'
      this.dialog(true)
    },
    onShowStudyGuide(active) {
       this.content = this.getSelectedQuestionType;
      // question create and draped 
      this.form.question_type_id = this.getSelectedQuestionType.id;
      this.form.active = active;
      this.submitOnlyTitle(active)
      // modal
       setTimeout(() => {
         document.querySelector(".md-dailog__writing").style.display = "none"
         if(document.querySelector("#dailog_vocabulary")){
            document.querySelector("#dailog_vocabulary").style.display = "none"
         }
       },50)
      this.dialogComponent = 'StudyGuide';
      this.dialog(true)
    },

    assignUtilsForQuestion(item) {
      let { prompt, answer_time, preparation_time } = item;
      this.prompt = prompt ? prompt : 'Default prompt not found';
      this.answerTime = secondToTime(answer_time);
      this.preTime = secondToTime(preparation_time);
    },
    submitOnlyTitle(active) {
      if(this.questionId){
        this.actUpdateQuestion({
          questionId: this.questionId,
          payload: this.form
        })
        .then(message => {
          this.setShowSnackbar(message);
        }).catch(error => {
           this.setShowSnackbar(error.response.data.message);
        });
      } else {
        this.submitQuestion(this.form)
          .then(response => {
            this.setShowSnackbar(response.message);
            this.question_id = response.question_id;
          
          }).catch(error => {
            this.setShowSnackbar(error.response.data.message);
        }).finally(() => this[(active == false) ? 'draftLoading' : 'loading'] = false);
      }
    },
    fetchQuestionDetails () {
      this.actQuestionDetails(this.questionId).then( item => {
        let { prompt , answer_time } = item;
        console.log(item,'item')
        this.selectQuestionType = item.question_type_id
        this.form.question_type_id = item.question_type_id;
        this.form.prompt_type = item.prompt_type;
        this.form.prompt = prompt;
        this.form.answer_time_type = item.answer_time_type;
        this.answerTime = secondToTime(answer_time)
        this.form.index = item.index;
        this.form.title = item.title;
        this.form.source = item.source;
        this.form.frequency = item.frequency;
        this.question_title = item.title;
        this.$nextTick(() => {
          if (this.$refs.child && typeof this.$refs.child.setValue === 'function') {
            this.$refs.child.setValue({
              question_options: item.question_options,
              question_title: item.title
            });
          }
        });

      });
    }
  },
  validations: {
    form: {
      index: {required},
      prompt_type: {required},
      answer_time_type: {required},
      source: {required}
    },
  },
  mounted() {
    // api call for writing types question
    this.actGetQuestionTypes(`?section=${this.type}`)
    .then(( type ) => {
      this.actQuestionTypeDetails(type.id)
        .then( item => {
          this.assignUtilsForQuestion(item);
        });
    });
  },
  created() {
      this.questionId && this.fetchQuestionDetails();
  }
};
</script>

<style>
@media only screen and (min-width: 1280px){
.md-setbuttom{
    position: absolute;
    margin-top: 12px;
}
}
.md-setbuttom{
    margin-left: 0px;
}
</style>
